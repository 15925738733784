$enable-gradients: true;

$min-contrast-ratio: 3;

$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

$input-placeholder-color: #b8b8b8;

$primary: #FF3644;

$datepicker__selected-color: $primary;
$datepicker__selected-color: $primary;
$datepicker__day-margin: 0.05rem;
$datepicker__font-size: 0.9rem;

$accordion-button-active-bg: #f8f9fa;
$accordion-button-active-color: #212529;

@import '~bootstrap/scss/bootstrap.scss';

@import "~react-datepicker/src/stylesheets/datepicker.scss";

@media (min-width: 576px) {
  .text-justify {
    text-align: justify;
  }
}
.form-control {
  &:read-only {
    color: $input-disabled-color;
    background-color: $input-disabled-bg;
    border-color: $input-disabled-border-color;
    opacity: 1;
  }
}

.nav-link:focus {
  outline: none !important;
}

.nav-tabs {
  background-color: rgba(255,255,255,0.6);
  .nav-item {
    min-width: 100px;
  }
}
.tab-content {
  margin-top: 10px;
}

.navbar .navbar-brand {
  display: block;
  position: relative;
  width: 180px;
  height: auto;
}

.react-datepicker-wrapper {
  .input-group-append {
    cursor: pointer;
  }
  .react-datepicker__close-icon {
    top: 11px;
    right: 38px;
    height: auto;

    &::after {
      background-color: #cccccc;
    }
  }
}

.react-datepicker-popper {
  z-index: 1000;
  * {
    outline: none;
  }
}

.custom-checkbox ~ .invalid-feedback,
.form-check ~ .invalid-feedback,
.react-datepicker-wrapper ~ .invalid-feedback {
  display: block;
}

.form-group {
  .react-datepicker-wrapper {
    display: block;
    width: 200px;
  }
}

.react-datepicker select {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-control, .form-select {
  &:focus {
    border-color: #ced4da;
  }
}

$b-table-sort-icon-bg-width: 0.65em !default;
$b-table-sort-icon-bg-height: 1em !default;
$b-table-sort-icon-bg-not-sorted: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'><path fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22zM51 101l25-23 24-22H1l25 22z'/></svg>") !default;
$b-table-sort-icon-bg-ascending: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'><path fill='black' d='M51 1l25 23 24 22H1l25-22z'/><path fill='black' opacity='.3' d='M51 101l25-23 24-22H1l25 22z'/></svg>") !default;
$b-table-sort-icon-bg-descending: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'><path fill='black' opacity='.3' d='M51 1l25 23 24 22H1l25-22z'/><path fill='black' d='M51 101l25-23 24-22H1l25 22z'/></svg>") !default;

@function bv-escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      $string: str-replace($string, $char, $encoded);
    }
  }

  @return $string;
}

.table.b-table {
  > thead,
  > tfoot {
    > tr {
      > [aria-sort] {
        cursor: pointer;
        background-image: none;
        background-repeat: no-repeat;
        background-size: $b-table-sort-icon-bg-width $b-table-sort-icon-bg-height;

        &:not(.b-table-sort-icon-left) {
          // Default is icon on the right
          background-position: right calc(#{$table-cell-padding-x} / 2) center;
          padding-right: calc(#{$table-cell-padding-x} + #{$b-table-sort-icon-bg-width});
        }

        &.b-table-sort-icon-left {
          // Left aligned sort icon
          background-position: left calc(#{$table-cell-padding-x} / 2) center;
          padding-left: calc(#{$table-cell-padding-x} + #{$b-table-sort-icon-bg-width});
        }
      }

      > [aria-sort="none"] {
        background-image: bv-escape-svg($b-table-sort-icon-bg-not-sorted);
      }

      > [aria-sort="ascending"] {
        background-image: bv-escape-svg($b-table-sort-icon-bg-ascending);
      }

      > [aria-sort="descending"] {
        background-image: bv-escape-svg($b-table-sort-icon-bg-descending);
      }
    }
  }
}

.invalid-feedback {
  display: block !important;
}

.addImageOptions {
  display: block;
  color: $secondary;
  &:hover {
    color: $primary;
  }
}

.image-uploader-container {
  display: flex;
  flex-wrap: wrap;
  &.active {
    .addImage {
      color: $primary;
    }
  }
  .addImage {
    height: 200px;
    width: 180px;
    font-size:80px;
    color: $secondary;
    &:hover {
      color: $primary;
    }
  }
  .image-uploader-preview {
    width: 180px;
    margin: 0 20px 20px 0;
    padding: 10px;
    background: #edf2f6;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid #d0dbe4;
    position: relative;
    img {
      width: 100%;
    }
    button {
      position: absolute;
      top: -9px;
      right: -9px;
    }
  }
}

.signature {
  @extend .card;
  height: 200px;
  border: 1px dashed $primary;
  canvas {
    height: 200px;
  }
  svg {
    position: absolute;
    right: 5px;
    top:5px;
  }
}

@media (min-width: 800px) {
  .signature {
    width: 600px;
  }
}

#pdfHolder {
  height: 100%;
  width: 100%;
}

@media(max-width:768px) {
  .nav-tabs {
    display: none;
  }
  .tab-content > .tab-pane {
    display: block;
  }
}

.form-label {
  margin-bottom: 0.1rem;
}
